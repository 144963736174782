import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import "./index.css";
import { Helmet } from "react-helmet";

ReactDOM.render(
    <>
        <Helmet>
            <title>sutarm.in</title>
        </Helmet>
        <App />
    </>,
    document.getElementById("root")
);
