import React from "react";
import { Route, Switch } from "wouter";

export const App = () => (
    <Switch>
        <Route path="/pp">Nope</Route>
        <Route path="/">
            <div>
                Hello, world! Did you hear about{" "}
                <a href="https://manymap.io">Manymap</a> already?
            </div>
            <div>
                О, моя радость! ❤️ С днём рождения тебя 😊 Поищи там, где мы уже
                очень скоро будем завтракать 🙃
            </div>
        </Route>
        <Route>Looks like this page doesn't exist yet</Route>
    </Switch>
);
